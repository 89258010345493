import { RefObject, forwardRef } from 'react'
import Image from 'next/image'
import clsx from 'clsx'

import WidthLimiter from 'containers/width-limiter/width-limiter'
import DoctorsStand from 'components/course/course-doctors-stand/course-doctors-stand'

import { data, info, imgData, MainSightsType } from './data'

import styles from './course-mainsights.module.scss'
import courseStyles from './../../search/courses-search/courses-search.module.scss'

interface ICourseMainSights {
  showMainSights?: boolean
  refsForNavigate?: Record<keyof typeof MainSightsType, RefObject<HTMLDivElement>>
}

const goToSightBlock = (refBlock: RefObject<HTMLDivElement>) => {
  if (!refBlock) return

  refBlock.current?.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
  })
}

const CourseMainSights = forwardRef<HTMLDivElement, ICourseMainSights>(
  ({ showMainSights, refsForNavigate }, ref) => {
    return (
      <>
        {showMainSights !== false && (
          <div className={styles.courseMainSights}>
            <WidthLimiter noLeftPadding noRightPadding noBottomPadding noTopPadding>
              <div className={styles.mainSightsWrapper}>
                {data.map(({ imgAttributes, text, theme }) => {
                  return (
                    <div
                      key={text}
                      className={styles.item}
                      onClick={() => {
                        if (refsForNavigate) goToSightBlock(refsForNavigate[theme])
                      }}
                    >
                      <Image className={styles.image} {...imgAttributes} width={70} height={70} />
                      <p>{text}</p>
                    </div>
                  )
                })}
              </div>
            </WidthLimiter>
          </div>
        )}

        <WidthLimiter noLeftPadding noRightPadding noBottomPadding noTopPadding>
          <div className={courseStyles.container} ref={ref}>
            <h2 className={courseStyles.sectionHeader}>Знания напрямую от ведущих специалистов</h2>
          </div>
          <DoctorsStand />
          <div className={clsx(styles.aboutLectursWrapper, courseStyles.container)}>
            {info.map((text) => (
              <div key={text} className={styles.aboutLecturs}>
                <div className={styles.icon}>
                  <Image {...imgData.tick} unoptimized={true} />
                </div>
                <p dangerouslySetInnerHTML={{ __html: text }}></p>
              </div>
            ))}
          </div>
        </WidthLimiter>
      </>
    )
  }
)

CourseMainSights.displayName = 'CourseMainSights'
export default CourseMainSights
