import { FC } from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'

import { STATIC_URL } from 'constants/data'
import { IAnyObj } from 'constants/types/common.types'
import { BaseSEO } from 'constants/SEO'
interface SEOMetatags {
  data: IAnyObj
  nofollow?: boolean
}

const titleImg = `https://medpoint.pro${STATIC_URL}/medpoint_new_main_seo.png`

const SEOMetatags: FC<SEOMetatags> = ({ data, nofollow }) => {
  const resultData = { ...BaseSEO, ...data }
  const { TITLE, DESCRIPTION, LINK, IMG } = resultData
  const router = useRouter()
  const isInsertCanonical = Number(router.query?.page) > 1 // Сео специалист сказал, что для страниц нужно, что бы у 1 страницы был каноникал , а у остальных его не было

  return (
    <Head>
      <title>{TITLE}</title>
      <meta name="title" content={TITLE} key="title" />
      <meta property="og:title" content={TITLE} key="ogtitle" />
      <meta property="twitter:title" content={TITLE} key="twtitle" />

      <meta name="description" content={DESCRIPTION} key="description" />
      <meta property="og:description" content={DESCRIPTION} key="ogdesc" />
      <meta name="twitter:description" content={DESCRIPTION} key="twdesc" />

      <meta property="og:image" content={IMG ? IMG : titleImg} key="ogimage" />
      <meta property="vk:image" content={IMG ? IMG : titleImg} key="vkimage" />
      <meta property="twitter:image" content={IMG ? IMG : titleImg} key="twimage" />
      <meta name="twitter:card" content="summary_large_image" />

      <meta property="og:url" content={LINK} key="ogurl" />
      <meta property="twitter:url" content={LINK} key="twurl" />
      {!isInsertCanonical && <link href={LINK} rel="canonical" />}
      {nofollow && <meta name="robots" content="noindex, nofollow" />}
    </Head>
  )
}

export default SEOMetatags
