import { FC, useEffect, useState } from 'react'
import Image from 'next/legacy/image'

import CardTransitionArea from 'containers/card-transition-area/card-transition-area'
import Skeleton from 'react-loading-skeleton'

import { lectursDataInit, imgPath, IlectursDataInit } from './data'
import { STATIC_PREVIEW_URL, currImageDomain } from 'constants/data'
import { BASE_URL } from 'constants/url'
import { getAxiosSingle } from 'pages/api/axios'

import styles from './course-doctors-stand.module.scss'

interface IDoctorsStand {
  lectursData?: IlectursDataInit[]
}

const DoctorsStand: FC<IDoctorsStand> = ({ lectursData = lectursDataInit }) => {
  const [isLoaded, setLoaded] = useState(false)
  useEffect(() => {
    const getData = async () => {
      const allInfoLectors = lectursData.map(({ idLector }) =>
        getAxiosSingle(`${BASE_URL.LECTUR}/${idLector}`)
      )
      Promise.all(allInfoLectors).then((results) => {
        results.map((result, index) => {
          lectursData[index].avatar = result.previewName
        })
        setLoaded(true)
      })
    }
    getData()
  }, [])

  return (
    <div className={styles.lectursWrapper}>
      <CardTransitionArea
        scrollModificator={styles.scroll}
        eventWrapperModificator={styles.scrollWrapper}
      >
        {lectursData.map(({ name, degree, speciality, avatar }) => (
          <div key={name} className={styles.lectur}>
            <div className={styles.avatar}>
              {avatar && isLoaded ? (
                <div className={styles.imgWrapper}>
                  <Image
                    src={`${currImageDomain}${STATIC_PREVIEW_URL}/lectur/${avatar}`}
                    width="238"
                    height="238"
                    alt="lector avatar"
                    onError={(e) => (e.currentTarget.src = imgPath.stub)}
                    unoptimized={true}
                  />
                </div>
              ) : (
                <Skeleton
                  baseColor="#E1DBCB"
                  className={styles.skeleton}
                  width={238}
                  height={238}
                />
              )}
            </div>

            <p className={styles.name}>{name}</p>
            <p className={styles.info}>{degree}</p>
            <p className={styles.info}>{speciality}</p>
          </div>
        ))}
      </CardTransitionArea>
    </div>
  )
}

export default DoctorsStand
