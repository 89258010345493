import clsx from 'clsx'
import { FC } from 'react'

import styles from './review.module.scss'

interface IReview {
  name: string
  speciality: string
  review: string
  modificator?: string
}

const Review: FC<IReview> = ({ name, speciality, review, modificator }) => {
  return (
    <div
      className={clsx(styles.block, {
        [modificator as string]: modificator,
      })}
    >
      <p className={styles.name}>{name}</p>
      <p className={styles.speciality}>{speciality}</p>
      <p className={styles.review}>{review}</p>
    </div>
  )
}

export default Review
