import { ReactElement } from 'react'
import Head from 'next/head'

import { TBreadCrumbs } from 'constants/SEO-breadcrumbs'

const SEOBreadCrumbs = ({ data }: { data: TBreadCrumbs }): ReactElement => {
  const dataJSON = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: data.map((bc, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      item: {
        '@id': bc.id,
        name: bc.name,
      },
    })),
  }

  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(dataJSON) }}
      />
    </Head>
  )
}

export default SEOBreadCrumbs
