import { STATIC_URL } from 'constants/data'

const imgData = {
  icon1: {
    src: `${STATIC_URL}/icons/icon_camera.svg`,
    alt: 'Баллы НМО',
  },
  icon2: {
    src: `${STATIC_URL}/icons/icon_video.svg`,
    alt: 'Формат обучения',
  },
  icon3: {
    src: `${STATIC_URL}/icons/icon_glasses_purple.svg`,
    alt: 'Исследования',
  },
  icon4: {
    src: `${STATIC_URL}/icons/icon_cert.svg`,
    alt: 'Удостоверение',
  },
  tick: {
    src: `${STATIC_URL}/icons/icon_tick_blue.svg`,
    width: 40,
    height: 40,
    alt: 'tick',
  },
}

enum MainSightsType {
  COURSES = 'COURSES',
  EASE = 'EASE',
  DOCTORS = 'DOCTORS',
  CERTIFICATION = 'CERTIFICATION',
}

const data = [
  {
    theme: MainSightsType.COURSES,
    imgAttributes: imgData.icon1,
    text: 'Курсы 18, 36 и 144 баллов НМО',
  },
  {
    theme: MainSightsType.EASE,
    imgAttributes: imgData.icon2,
    text: 'Удобная платформа для обучения',
  },
  {
    theme: MainSightsType.DOCTORS,
    imgAttributes: imgData.icon3,
    text: 'Курсы подготовлены практикующими врачами',
  },
  {
    theme: MainSightsType.CERTIFICATION,
    imgAttributes: imgData.icon4,
    text: 'Удостоверение о повышении квалификации',
  },
]

const info = [
  'Наши лекторы имеют ученую <br /> степень',
  'Преподают в ведущих вузах <br /> страны',
  'Являются практикующими <br /> врачами',
]

export { imgData, data, info, MainSightsType }
