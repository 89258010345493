import { FC, Children, useState } from 'react'
import { clsx } from 'clsx'
import { Swiper, SwiperSlide } from 'swiper/react'
import { EffectFade, Autoplay, Pagination } from 'swiper/modules'

import styles from './swiper-progress.module.scss'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/effect-fade'

interface ISwiperProgress {
  id: string
  children: React.ReactNode
  navModificator?: string
}

const SwiperProgress: FC<ISwiperProgress> = ({ id, navModificator, children }) => {
  const [hh, setHH] = useState(0)
  const swiperProps = {
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },

    effect: 'fade',
    loop: true,
    pagination: {
      el: `.${styles.pagination}#pagination-${id}`,
      type: 'bullets' as const,
      clickable: true as const,

      renderBullet: function (index: number, className: string) {
        return (
          '<span class="' +
          className +
          ' ' +
          styles.swiper_pagination_bullet +
          '">' +
          '<i></i>' +
          '</span>'
        )
      },
    },
    slidesPerView: 1,
    speed: 500,
    spaceBetween: '15px',
    modules: [EffectFade, Autoplay, Pagination],
  }

  const childrenWrapped = Children.map(children, (child) => {
    if (!child) return
    return <SwiperSlide className={styles.slide}>{child}</SwiperSlide>
  })

  const customBullet = Children.map(children, (_, index) => {
    return (
      <div
        className={clsx(styles.customBullet, {
          [styles.bulletShow]: index < hh,
          [styles.bulletActive]: index === hh,
        })}
      ></div>
    )
  })

  return (
    <div className={clsx(styles.swiperWrapper, `${hh}`)}>
      <Swiper
        {...swiperProps}
        onSlideChange={(info: any) => {
          setHH(info?.realIndex || 0)
        }}
        key={id}
      >
        {childrenWrapped}
      </Swiper>
      <div
        className={clsx(styles.navWrap, {
          [navModificator as string]: navModificator,
        })}
      >
        {Number(childrenWrapped?.length) > 1 && (
          <div className={styles.bulletsWrap}>{customBullet}</div>
        )}
        <div className={styles.pagination} id={`pagination-${id}`}></div>
      </div>
    </div>
  )
}

export default SwiperProgress
