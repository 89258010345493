import { STATIC_URL } from 'constants/data'

interface IlectursDataInit {
  name: string
  degree: string
  speciality: string
  idLector: number
  avatar: string
}

const lectursDataInit = [
  {
    name: 'Ткачева О.Н.',
    degree: 'д.м.н.',
    speciality: 'гериатрия',
    idLector: 6,
    avatar: '',
  },
  {
    name: 'Наумов А.В.',
    degree: 'д.м.н.',
    speciality: 'терапия',
    idLector: 1,
    avatar: '',
  },
  {
    name: 'Дудинская Е.Н.',
    degree: 'д.м.н.',
    speciality: 'эндокринология',
    idLector: 11,
    avatar: '',
  },
  {
    name: 'Гиляревский С.Р.',
    degree: 'д.м.н.',
    speciality: 'кардиология',
    idLector: 172,
    avatar: '',
  },
  {
    name: 'Мхитарян Э.А.',
    degree: 'к.м.н.',
    speciality: 'неврология',
    idLector: 4,
    avatar: '',
  },
]

const imgPath = {
  stub: `${STATIC_URL}/courses/stub_lectors.png`,
}

export { lectursDataInit, imgPath }
export type { IlectursDataInit }
